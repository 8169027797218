import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";

import { Box, Card, CardContent, Typography } from "@mui/material";

import { dark } from "@mui/material/styles/createPalette";
import { Icon } from "@mui/material";
import { max } from "lodash";
function InfoCardItem({
  name,
  value,
  percentageChange,
  color,
  icon,
  dataType,
}) {
  const colorTransform = () => {
    let colorCode;
    let darkColor;
    if (color === "lightpink") {
      colorCode = "#ffe6e6";
      darkColor = "#e91e63";
    } else if (color === "lightblue") {
      colorCode = "#e6f7ff";
      darkColor = "#3f51b5";
    } else if (color === "lightgreen") {
      colorCode = "#f0fff4";
      darkColor = "#4caf50";
    } else if (color === "lightyellow") {
      colorCode = "#ffffe6";
      darkColor = "#ffeb3b";
    } else if (color === "lightpurple") {
      colorCode = "#f5e1ff";
      darkColor = "#9c27b0";
    }
    console.log(colorCode);
    return { colorCode, darkColor };
  };
  let useIcon;
  const getIcon = () => {
    if (name === "Busiest Season") {
      if (value === "Winter") {
        useIcon = "ac_unit";
      } else if (value === "Spring") {
        useIcon = "local_florist";
      } else if (value === "Summer") {
        useIcon = "wb_sunny";
      } else if (value === "Fall") {
        useIcon = "eco";
      }
    } else if (name === "Total Reservations") {
      useIcon = "group";
    } else if (name === "Total Occupancy Rate") {
      useIcon = "luggage";
    } else if (name === "Average Duration") {
      useIcon = "access_time";
    } else if (name === "Average Long stay duration") {
      useIcon = "access_time";
    } else if (name === "Top Check-in Day") {
      useIcon = "flight_land";
    } else if (name === "Top Check-out Day") {
      useIcon = "flight_takeoff";
    } else {
      useIcon = icon;
    }
    return useIcon;
  };
  const cardIcon = getIcon();
  console.log("Name: ", name, "Value: ", value, "Icon: ", cardIcon, dataType);
  const { colorCode, darkColor } = colorTransform(color);
  return (
    <Card
      sx={{
        backgroundColor: colorCode, // Light pink background
        borderRadius: "16px",
        boxShadow: "none",
        // textAlign: "center",
        padding: "16px",
        // minWidth: "300px",
        // width: "100%",
        maxWidth: "300px",
      }}
    >
      <CardContent>
        <Box
          display="flex"
          mb={2}
          // circle icon
          alignItems="center"
          justifyContent="center"
          width={48}
          height={48}
          borderRadius={16}
          bgcolor={darkColor}
        >
          <Icon
            sx={{ fontSize: 50, color: "#ffffff" }} // Icon styling
          >
            {cardIcon}
          </Icon>
        </Box>
        <ArgonBox
          sx={{
            maxWidth: "200px",
            maxHeight: "100px",
          }}
        >
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="bold">
              {`${value} ${dataType === "percentage" ? "%" : ""}`}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="body2" color="textSecondary">
              {name}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography
              variant="caption"
              sx={{ color: "#5861e3", marginTop: "8px" }}
            >
              {percentageChange}% from yesterday
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </CardContent>
    </Card>
  );
}
export default InfoCardItem;
