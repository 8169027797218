/* eslint-disable react/prop-types */

import HostHiveDialog from "components/HostHiveDialog";
import MultiSelectFilter from "components/HostHiveMultiSelectFilter";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";

function FilterByProperty({
  triggerFetch,
  setTriggerFetch,
  selectedProperties,
  setSelectedProperties,
  propertyFilterSelectionVisible,
  setPropertyFilterSelectionVisible,
  availableProperties,
  setAvailableProperties,
}) {
  const handleFilterByProperty = () => {
    if (triggerFetch) {
      setTriggerFetch(!triggerFetch);
    }
    setPropertyFilterSelectionVisible(false);
  };

  return (
    <>
      <HostHiveDialog
        open={propertyFilterSelectionVisible}
        onClose={() => setPropertyFilterSelectionVisible(false)}
        onSave={() => handleFilterByProperty()}
        includeSave={true}
        // includeDelete={true}
        deleteText="Clear"
        includeClose={true}
        title="Filter by Property"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Apply"
        dialogObjects={
          <>
            <MultiSelectFilter
              selectType="properties"
              selectedItems={selectedProperties}
              setSelectedItems={setSelectedProperties}
              availableItems={availableProperties}
              setAvailableItems={setAvailableProperties}
            />
          </>
        }
      />
    </>
  );
}

export default FilterByProperty;
