/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import { Grid } from "@mui/material";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from "layouts/hostHiveAdministration/customers/companyPage/components/Tables/DataTable"; // from "examples/Tables/DataTable";
// Data

import { useEffect, useState } from "react";
import AdministrationApi from "api/administrationApi";
import { generateColumns } from "layouts/hostHiveAdministration/customers/companyPage/components/companiesColumns";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveLoading from "components/HostHiveLoading";
import { last } from "lodash";
import ArgonSelect from "components/ArgonSelect";
function CustomerDataTables() {
  const administrationApi = new AdministrationApi();
  const [permitted, setPermitted] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [apiRoute, setApiRoute] = useState("");
  const [invokingApi, setInvokingApi] = useState(false);
  const companyColumns = generateColumns({
    triggerFetch,
  });
  const [companiesTableData, setCompaniesTableData] = useState({
    columns: companyColumns,
    rows: [
      {
        id: "",
        name: "",
        created: "",
      },
    ],
  });

  const attemptReachability = async () => {
    try {
      await administrationApi.getReachability();
      setPermitted(true);
      console.log("Permitted");
      getCompanies();
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanies = async () => {
    try {
      const response = await administrationApi.getCompanies();
      setCompaniesTableData({
        columns: companyColumns,
        rows: response.data.companies,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    attemptReachability();
  }, [triggerFetch]);

  //password: str
  // email: Optional[str] = None;
  // firstName: Optional[str] = None;
  // lastName: Optional[str] = None;
  // companyName: Optional[str] = None;

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("Qwerty1234!");
  const [newCompanyOpen, setNewCompanyOpen] = useState(false);
  const handleSaveCompany = async () => {
    const request = {
      email,
      firstName,
      lastName,
      companyName,
      password,
    };
    try {
      await administrationApi.createCompany(request);
      setNewCompanyOpen(false);
      setTriggerFetch(!triggerFetch);
    } catch (error) {
      console.error(error);
    }
  };
  const handleScrubPhantomUsers = async () => {
    try {
      await administrationApi.removePhantomUsers();
    } catch (error) {
      console.error(error);
    }
  };
  const selectRouteInitial = {
    label: "Select Route",
    value: "",
  };
  const handleInvokePut = async () => {
    try {
      setInvokingApi(true);
      const response = await administrationApi.putRoute(apiRoute);
      if (apiRoute === "system-api-key") {
        let apiKey = response.data.apiKey;
        alert(`System API Key: ${apiKey}`);
      }
      // console.log("apiRoute", apiRoute);
      setApiRoute("");
      setSelectedApiRoute(selectRouteInitial);
    } catch (error) {
      console.error(error);
    }
    setInvokingApi(false);
  };

  const putRouteOptions = [
    {
      label: "Migrate Guest Books",
      value: "guestbook-migration",
    },
    {
      label: "Create System API Key",
      value: "system-api-key",
    },
    {
      label: "Delete Secrets from Secrets Manager",
      value: "delete-secrets",
    },
  ];
  const [selectedApiRoute, setSelectedApiRoute] = useState(selectRouteInitial);
  const handleApiRouteSelected = (e) => {
    setSelectedApiRoute(e);
    setApiRoute(e.value);
    console.log("e", e);
  };

  return (
    <>
      <HostHiveDialog
        open={newCompanyOpen}
        onClose={() => setNewCompanyOpen(false)}
        onSave={() => handleSaveCompany()}
        includeSave={true}
        includeClose={true}
        title="Create Company"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <>
            <ArgonBox
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonInput
                    fullWidth
                    label="First Name"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonInput
                    fullWidth
                    label="Last Name"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonInput
                    fullWidth
                    label="Email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonInput
                    fullWidth
                    label="Company Name"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonInput
                    fullWidth
                    label="Password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </>
        }
      />
      {permitted && (
        <DashboardLayout>
          <ArgonBox pt={6} pb={3}>
            <Card>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="medium">
                  Company Management
                </ArgonTypography>
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Manage your companies
                </ArgonTypography>
              </ArgonBox>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0 20px",
                      marginBottom: "20px",
                    }}
                  >
                    <ArgonButton
                      onClick={() => setNewCompanyOpen(true)}
                      variant="gradient"
                      color="secondary"
                    >
                      Create Company
                    </ArgonButton>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0 20px",
                      marginBottom: "20px",
                    }}
                  >
                    <ArgonButton
                      onClick={() => handleScrubPhantomUsers()}
                      variant="gradient"
                      color="secondary"
                    >
                      Scrub Phantom Users
                    </ArgonButton>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "0 20px",
                      marginBottom: "20px",
                    }}
                  >
                    <ArgonSelect
                      options={putRouteOptions}
                      value={selectedApiRoute}
                      onChange={(e) => handleApiRouteSelected(e)}
                    />
                    {!invokingApi ? (
                      <ArgonButton
                        onClick={() => handleInvokePut()}
                        variant="gradient"
                        color="secondary"
                        disabled={selectedApiRoute.value === ""}
                      >
                        Invoke
                      </ArgonButton>
                    ) : (
                      <HostHiveLoading
                        message="Executing API Call"
                        elipse={true}
                      />
                    )}
                  </ArgonBox>
                </Grid>
              </Grid>
              <DataTable table={companiesTableData} canSearch />
            </Card>
          </ArgonBox>
        </DashboardLayout>
      )}
    </>
  );
}

export default CustomerDataTables;
