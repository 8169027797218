import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  CircularProgress,
  LinearProgress,
  // Typography,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { position } from "@chakra-ui/react";

import { color } from "framer-motion";
import { icon } from "leaflet";
import CardTitle from "../CardTitle";
import { min } from "lodash";
function AISummary({}) {
  return (
    <Card>
      <ArgonBox
        sx={{
          padding: 2,
          minHeight: "500px",
        }}
      >
        <CardTitle title="AI Summary" />
        <CardContent>
          <ArgonTypography variant="caption">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </ArgonTypography>
        </CardContent>
      </ArgonBox>
    </Card>
  );
}

export default AISummary;
