import { border } from "@chakra-ui/react";
import colors from "assets/theme/base/colors";
import ArgonBox from "components/ArgonBox";
import ArgonBadge from "components/ArgonBadge";
import ArgonTypography from "components/ArgonTypography";
// import { min } from "lodash";
import { Divider } from "@mui/material";
// import colors from "assets/theme/base/colors";
// import { color } from "html2canvas/dist/types/css/types/color";
function QuickFactRow({ key, name, value, color }) {
  const colorTransform = () => {
    let colorCode;
    let darkColor;
    if (color === "lightpink") {
      colorCode = "#ffe6e6";
      darkColor = "#e91e63";
    } else if (color === "lightblue") {
      colorCode = "#e6f7ff";
      darkColor = "#3f51b5";
    } else if (color === "lightgreen") {
      colorCode = "#f0fff4";
      darkColor = "#4caf50";
    } else if (color === "lightyellow") {
      colorCode = "#ffffe6";
      darkColor = "#ffeb3b";
    } else if (color === "lightpurple") {
      colorCode = "#f5e1ff";
      darkColor = "#9c27b0";
    } else if (color === "lightorange") {
      colorCode = "#fff3e0";
      darkColor = "#ff9800";
    }
    console.log(colorCode);
    return { colorCode, darkColor };
  };

  const { colorCode, darkColor } = colorTransform(color);

  const CustomBadge = () => {
    return (
      <ArgonBox
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",

          minWidth: "150px",
          backgroundColor: colorCode,
          borderRadius: "8px",
          padding: "7px",
          border: `1px solid ${darkColor}`,
        }}
      >
        <ArgonTypography
          variant="caption"
          sx={{
            color: darkColor,
          }}
        >
          {value}
        </ArgonTypography>
      </ArgonBox>
    );
  };
  return (
    <>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        key={key}
      >
        <ArgonBox>
          <ArgonTypography variant="caption">{name}</ArgonTypography>
        </ArgonBox>
        <CustomBadge />
      </ArgonBox>
      <Divider />
    </>
  );
}

export default QuickFactRow;
