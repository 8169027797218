/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import HostHiveDialog from "components/HostHiveDialog";
import ArgonInput from "components/ArgonInput";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonSelect from "components/ArgonSelect";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import "./PrintStyles.css";
import ArgonBadge from "components/ArgonBadge";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import MultiSelectFilter from "components/HostHiveMultiSelectFilter";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { IconButton, Tooltip, Icon } from "@mui/material";
import { useState, useEffect } from "react";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Invoice page components

// Images
import logoCT from "assets/images/logo-hh.png";
import logoCTDark from "assets/images/logo-hh.png";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import CategoriesApi from "api/categoriesApi";
import PropertiesApi from "api/propertiesApi";
import AccountingApi from "api/accountingApi";
import { useTranslation } from "react-i18next";
import HostHiveFilterByProperty from "components/HostHiveFilterByProperty";
import HostHiveFilterByCategory from "components/HostHiveFilterByCategory";
import { Filter } from "@mui/icons-material";
import { use } from "i18next";
function FinanaceReport({
  reportQueryParams = null,
  setReportQueryParams = null,
}) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const { t } = useTranslation("common");
  const accountingApi = AccountingApi();
  const categoriesApi = CategoriesApi();
  const propertiesApi = PropertiesApi();
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderWidth } = borders;
  const { light, dark } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  const [reportData, setReportData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [properties, setProperties] = useState([]);
  const [property, setProperty] = useState(null);
  const [category, setCategory] = useState(null);
  const [applySearch, setApplySearch] = useState(false);
  const [filterOptionsOpen, setFilterOptionsOpen] = useState(false);
  useEffect(() => {
    async function fetchReportData() {
      const newReportQueryParams = { ...reportQueryParams };

      if (startDate) {
        console.log("startDate", startDate);
        newReportQueryParams.startDate = startDate;
      }

      if (endDate) {
        console.log("endDate", endDate);
        newReportQueryParams.endDate = endDate;
      }

      if (property?.value) {
        console.log("property", property.value);
        newReportQueryParams.propertyId = property.value;
      }

      if (category?.value) {
        console.log("category", category.value);
        newReportQueryParams.categoryId = category.value;
      }

      if (selectedProperties.length > 0) {
        console.log("selectedProperties", selectedProperties);
        newReportQueryParams.propertyIds = selectedProperties.join(",");
      }

      if (selectedCategories.length > 0) {
        console.log("selectedCategories", selectedCategories);
        newReportQueryParams.categoryIds = selectedCategories.join(",");
      }

      setReportQueryParams(newReportQueryParams);

      try {
        const response = await accountingApi.getFinanceReport(
          newReportQueryParams
        );
        setReportData(response.data);
      } catch (error) {
        console.error(error);
      }
      setFilterOptionsOpen(false);
    }

    fetchReportData();
  }, [applySearch]);

  useEffect(() => {
    async function fetchFilterData() {
      const categoriesQueryParams = {
        companyId: company?.id,
        type: "accounting",
      };
      const propertyQueryParams = {
        companyId: company?.id,
      };
      const categories = await categoriesApi.getCategories(
        categoriesQueryParams
      );
      const categoriesResponse = categories.data;
      // let categoryOptions = categoriesResponse.map((category) => ({
      //   value: category.id,
      //   label: category.name,
      // }));

      let categoryOptions = categoriesResponse.map((category) => ({
        color: category?.color,
        value: category.id,
        text: category.name,
        label: (
          <ArgonBadgeDot
            color={category?.color}
            size="md"
            badgeContent={category?.name}
          />
        ),
      }));

      setCategories(categoryOptions);

      const properties = await propertiesApi.getProperties(propertyQueryParams);
      const propertiesResponse = properties.data;
      let propertyOptions = propertiesResponse.map((property) => ({
        value: property.id,
        label: property.name,
      }));
      setProperties(propertyOptions);
    }

    fetchFilterData();
  }, []);
  const [startDate, setStartDate] = useState(
    reportQueryParams?.startDate || null
  );
  const [endDate, setEndDate] = useState(reportQueryParams?.endDate || null);
  const [sendingReport, setSendingReport] = useState(false);

  const handleSetStartDate = (date, param) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (param === "startDate") {
      // setStartDate(useDate);
      // setReportQueryParams({ ...reportQueryParams, startDate: useDate });
      setStartDate(useDate);
    } else {
      // setReportQueryParams({ ...reportQueryParams, endDate: useDate });
      setEndDate(useDate);
    }
  };

  const handlePropertySelected = (e) => {
    setProperty(e);
  };

  // const [properties,setProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [availableProperties, setAvailableProperties] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [propertyFilterSelectionVisible, setPropertyFilterSelectionVisible] =
    useState(false);
  const [categoryFilterSelectionVisible, setCategoryFilterSelectionVisible] =
    useState(false);
  const [selectedPropertiesView, setSelectedPropertiesView] = useState([]);
  const [selectedCategoriesView, setSelectedCategoriesView] = useState([]);
  useEffect(() => {
    console.log("selectedProperties", selectedProperties);
    // given selectedProperty.id, find the property name from availableProperties
    let selectedPropertiesView = selectedProperties.map((selectedProperty) => {
      let property = availableProperties.find(
        (property) => property.id === selectedProperty
      );
      return {
        name: property?.name,
        id: property?.id,
      };
    });
    setSelectedPropertiesView(selectedPropertiesView);
    console.log("selectedPropertiesView", selectedPropertiesView);
  }, [selectedProperties]);

  useEffect(() => {
    let selectedCategoriesView = selectedCategories.map((selectedCategory) => {
      let category = availableCategories.find(
        (category) => category.id === selectedCategory
      );
      return {
        name: category?.name,
        id: category?.id,
      };
    });
    setSelectedCategoriesView(selectedCategoriesView);
    console.log("selectedCategoriesView", selectedCategoriesView);
  }, [selectedCategories]);

  return (
    <>
      <ArgonBox id="invoiceArea" style={{ padding: "20px" }}>
        <HostHiveDialog
          open={filterOptionsOpen}
          onClose={() => setFilterOptionsOpen(false)}
          onSave={() => setApplySearch(!applySearch)}
          includeSave={true}
          saveDisabled={false}
          includeClose={true}
          title="Report Filter"
          fullScreen={false}
          maxWidth="sm"
          cancelButtonText="Close"
          submitButtonText="Apply"
          dialogObjects={
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <ArgonBox>
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        // disabled={loadingTransactions}
                      >
                        Category
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox>
                      <ArgonTypography
                        variant="body2"
                        color="white"
                        fontWeight="medium"
                      >
                        <IconButton
                          onClick={() =>
                            setCategoryFilterSelectionVisible(true)
                          }
                        >
                          <Icon color="info">edit</Icon>
                        </IconButton>
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>

                  <Grid container spacing={2}>
                    {" "}
                    {selectedCategoriesView.slice(0, 5).map(
                      (
                        category,
                        index // Slice the first 5 properties
                      ) => (
                        <Grid
                          item
                          xs={4}
                          key={index} // Always use keys in lists for better performance
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <ArgonBadge
                            variant="contained"
                            container
                            badgeContent={category?.name}
                            color="info"
                          />
                        </Grid>
                      )
                    )}
                    {selectedCategoriesView.length > 5 && ( // Check if there are more than 5 properties
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            setCategoryFilterSelectionVisible(true)
                          }
                        >
                          <ArgonBadge
                            variant="contained"
                            container
                            badgeContent={`${
                              selectedCategoriesView.length - 5
                            } more`}
                            color="info"
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <HostHiveFilterByCategory
                    triggerFetch={null}
                    setTriggerFetch={null}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    categoryFilterSelectionVisible={
                      categoryFilterSelectionVisible
                    }
                    setCategoryFilterSelectionVisible={
                      setCategoryFilterSelectionVisible
                    }
                    availableCategories={availableCategories}
                    setAvailableCategories={setAvailableCategories}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <ArgonBox>
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        // disabled={loadingTransactions}
                      >
                        Property
                      </ArgonTypography>
                    </ArgonBox>

                    <ArgonBox>
                      <ArgonTypography
                        variant="body2"
                        color="white"
                        fontWeight="medium"
                      >
                        <IconButton
                          onClick={() =>
                            setPropertyFilterSelectionVisible(true)
                          }
                        >
                          <Icon color="info">edit</Icon>
                        </IconButton>
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>

                  <Grid container spacing={2}>
                    {" "}
                    {selectedPropertiesView.slice(0, 5).map(
                      (
                        property,
                        index // Slice the first 5 properties
                      ) => (
                        <Grid
                          item
                          xs={4}
                          key={index} // Always use keys in lists for better performance
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "20px",
                          }}
                        >
                          <ArgonBadge
                            variant="contained"
                            container
                            badgeContent={property?.name}
                            color="info"
                          />
                        </Grid>
                      )
                    )}
                    {selectedPropertiesView.length > 5 && ( // Check if there are more than 5 properties
                      <Grid
                        item
                        xs={4}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            setPropertyFilterSelectionVisible(true)
                          }
                        >
                          <ArgonBadge
                            variant="contained"
                            container
                            badgeContent={`${
                              selectedPropertiesView.length - 5
                            } more`}
                            color="info"
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <HostHiveFilterByProperty
                    triggerFetch={null}
                    setTriggerFetch={null}
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                    propertyFilterSelectionVisible={
                      propertyFilterSelectionVisible
                    }
                    setPropertyFilterSelectionVisible={
                      setPropertyFilterSelectionVisible
                    }
                    availableProperties={availableProperties}
                    setAvailableProperties={setAvailableProperties}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ArgonTypography variant="caption" fontWeight="bold">
                    FROM
                  </ArgonTypography>
                  <ArgonDatePicker
                    input={{ placeholder: "Select a date" }}
                    onChange={(e) => handleSetStartDate(e, "startDate")}
                    // disabled={loadingTransactions}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ArgonTypography variant="caption" fontWeight="bold">
                    TO
                  </ArgonTypography>
                  <ArgonDatePicker
                    input={{ placeholder: "Select a date" }}
                    onChange={(e) => handleSetStartDate(e, "endDate")}
                    // disabled={loadingTransactions}
                  />
                </Grid>
              </Grid>
            </>
          }
        />
        {/* Invoice header */}
        <Grid
          container
          justifyContent="space-between"
          style={{ marginBottom: "20px" }}
        >
          <Grid item xs={12}>
            <ArgonBox width="100%" textAlign="right" lineHeight={1}>
              <ArgonButton
                variant="gradient"
                color="warning"
                onClick={() => setFilterOptionsOpen(true)}
              >
                Filter
              </ArgonButton>
            </ArgonBox>
          </Grid>

          {/* Company Logo and Address */}
          <Grid item xs={12} md={6}>
            <ArgonBox
              component="img"
              src={darkMode ? logoCT : logoCTDark}
              width="25%"
              p={1}
              mb={1}
            />
            <ArgonTypography variant="h6" fontWeight="medium">
              {company?.name}
            </ArgonTypography>
            {company?.address?.address1 ? (
              <ArgonTypography variant="body2" color="secondary">
                {company?.address?.address1}
                <br />
                {company?.address?.city}, {company?.address?.state}
              </ArgonTypography>
            ) : null}
          </Grid>
          {/* Date Range */}
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            <ArgonTypography variant="h6" color="secondary" fontWeight="medium">
              Period
            </ArgonTypography>
            <ArgonTypography variant="h5" fontWeight="medium">
              {reportData?.fromDate} - {reportData?.toDate}
            </ArgonTypography>
          </Grid>
        </Grid>

        {/* Financial Summary */}
        <Grid
          container
          justifyContent="space-between"
          style={{ marginBottom: "20px" }}
        >
          <Grid item xs={6}>
            <ArgonTypography variant="h6" color="secondary" fontWeight="medium">
              Income: $ {reportData?.totalIncome.toLocaleString()}
            </ArgonTypography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <ArgonTypography variant="h6" color="secondary" fontWeight="medium">
              Expenses: $ {reportData?.totalExpenses.toLocaleString()}
            </ArgonTypography>
          </Grid>
        </Grid>

        {/* Invoice Table */}
        <ArgonBox style={{ overflowX: "auto" }}>
          <Table>
            {/* Table Header */}
            <ArgonBox bgColor="dark" component="thead">
              <TableRow>
                <ArgonBox
                  component="th"
                  py={1.5}
                  px={1}
                  textAlign="left"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <ArgonTypography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                  >
                    Category
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  borderBottom={borderBottom}
                >
                  <ArgonTypography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                  >
                    Income
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  borderBottom={borderBottom}
                >
                  <ArgonTypography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                  >
                    Expense
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  borderBottom={borderBottom}
                >
                  <ArgonTypography
                    variant="h6"
                    color="white"
                    fontWeight="medium"
                  >
                    Net
                  </ArgonTypography>
                </ArgonBox>
              </TableRow>
            </ArgonBox>
            {/* Table Body */}
            {reportData?.items?.map((item, index) => (
              <TableRow key={index}>
                <ArgonBox
                  component="td"
                  textAlign="left"
                  p={1}
                  borderBottom={borderBottom}
                >
                  <ArgonTypography variant="body2" color="text">
                    {item?.categoryName}
                  </ArgonTypography>
                </ArgonBox>

                <ArgonBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  borderBottom={borderBottom}
                >
                  <ArgonTypography variant="body2" color="text">
                    {item?.income ? `$ ${item?.income?.toLocaleString()}` : "-"}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  borderBottom={borderBottom}
                >
                  <ArgonTypography variant="body2" color="text">
                    {item?.expense
                      ? `$ ${item?.expense?.toLocaleString()}`
                      : "-"}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox
                  component="td"
                  textAlign="left"
                  py={1}
                  pr={1}
                  pl={3}
                  borderBottom={borderBottom}
                >
                  <ArgonTypography variant="body2" color="text">
                    {item?.expense ? `$ ${item?.net?.toLocaleString()}` : "-"}
                  </ArgonTypography>
                </ArgonBox>
              </TableRow>
            ))}

            <TableRow>
              <ArgonBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <ArgonTypography variant="h5">Total</ArgonTypography>
              </ArgonBox>
              <ArgonBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <ArgonTypography variant="h5">
                  $ {reportData?.totalIncome.toLocaleString()}
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <ArgonTypography variant="h5">
                  $ {reportData?.totalExpenses.toLocaleString()}
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox
                component="td"
                textAlign="left"
                py={1}
                pr={1}
                pl={3}
                borderBottom={borderBottom}
              >
                <ArgonTypography variant="h5">
                  $ {reportData?.subTotal.toLocaleString()}
                </ArgonTypography>
              </ArgonBox>
            </TableRow>
          </Table>
        </ArgonBox>

        {/* Invoice Footer */}
        {/* ... */}
      </ArgonBox>
    </>
  );
}

export default FinanaceReport;
