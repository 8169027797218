import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useMemo } from "react";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  CircularProgress,
  LinearProgress,
  // Typography,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { position } from "@chakra-ui/react";
import colors from "assets/theme/base/colors";
import { dark } from "@mui/material/styles/createPalette";
import { Icon } from "@mui/material";
import ThinBarChart from "layouts/wrappedReport/components/SeasonOccupancy/components/BarCharts/ThinBarChart";
import HorizontalBarChart from "./components/BarCharts/HorizontalBarChart";
import { Bar } from "react-chartjs-2";
// import ThinBarChart from "./components/LineCharts/ThinBarChart";
import CardTitle from "../CardTitle";
function SeasonOccupancy({ data }) {
  return (
    <Card>
      <ArgonBox
        sx={{
          padding: 2,
          minHeight: "500px",
        }}
      >
        <CardTitle title="Season Occupancy" />
        <CardContent>
          <HorizontalBarChart chart={data} />
        </CardContent>
      </ArgonBox>
    </Card>
  );
}
export default SeasonOccupancy;
