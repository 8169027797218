import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// sweetalert2 components
import Swal from "sweetalert2";
import HowToVideoButton from "layouts/management/components/VideoIconButton";

import { useState, useEffect, useContext } from "react";
import IntegrationSettingsDataCard from "layouts/management/components/PropertyIntegrationSettingsDataCard";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveLoading from "components/HostHiveLoading";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import PropertyContext from "context/PropertyContext";

import GuestBookApi from "api/guestBookApi";

import { useTranslation } from "react-i18next";
import SettingsApi from "api/settingsApi";

import ApplicationApi from "api/applicationApi";
import CompaniesApi from "api/companyApi";
import ChecklistsApi from "api/checkListsApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";
import { set } from "lodash";
import { VideoLibraryHelper } from "services/videoLibraryHelper";

function PropertyInfoCard({
  propertyId,
  companyId,
  triggerExternalCalendarFetch,
  hasPlusMembership,
}) {
  const { t } = useTranslation("common");
  const guestBookApi = GuestBookApi();
  const settingsApi = SettingsApi();

  const checkListsApi = ChecklistsApi();
  const serviceNamesApi = ServiceNameApi();
  const companiesApi = CompaniesApi();
  const usersApi = UsersApi();

  const labels = [];
  const values = [];
  const [integrationSettingsOpen, setIntegrationSettingsOpen] = useState(false);
  const [propertySettingsOpen, setPropertySettingsOpen] = useState(false);

  const applicationApi = ApplicationApi();

  // const { property, setProperty } = useContext(PropertyContext);

  const [propertySettings, setPropertySettings] = useState(null);
  const [integrationSettingsTypeName, setIntegrationSettingsTypeName] =
    useState(null);

  const [applicationIntegrationTypes, setApplicationIntegrationTypes] =
    useState([]);

  const [availableServicers, setAvailableServicers] = useState([]);
  const [availableChecklists, setAvailableChecklists] = useState([]);
  const [availableServiceNames, setAvailableServiceNames] = useState([]);
  const [availableSubAssigneeUsers, setAvailableSubAssigneeUsers] = useState(
    []
  );

  const video = VideoLibraryHelper("syncCalendar").videoId;

  useEffect(() => {
    async function fetchPropertySettings() {
      try {
        const response = await settingsApi.getSetting(
          "integration",
          propertyId
        );
        setPropertySettings(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    if (propertyId) {
      fetchPropertySettings();
      console.log("propertyId", propertyId);
    }
  }, []);

  const integrationTypes = [
    { id: "62e039ea-2a35-42a0-bd57-12a397bd4789", name: "Airbnb" },
    { id: "ff19b59b-bd9d-44e2-bbc8-1876f63ae6b6", name: "Guesty for Hosts" },
    { id: "b4de0926-f507-42a0-a84a-1929bdc747a3", name: "Guesty" },
    { id: "59da4cdf-3650-40bd-880c-46b0b310e3d9", name: "VRBO" },
    {
      id: "24a52b2b-d556-4f2f-b1aa-cfc70a936863",
      name: "Lodgify",
    },
  ];

  // const [hasPlusMembership, setHasPlusMembership] = useState(false);

  useEffect(() => {
    async function fetchOtherElements() {
      let checklistResponse;
      let serviceNamesResponse;
      let servicePartnersResponse;
      let companyUsersResponse;
      let applicationIntegrationTypesResponse;
      if (!hasPlusMembership) {
        console.log("No permissions for chechlists");
      } else {
        try {
          checklistResponse = await checkListsApi.getChecklists({
            companyId: companyId,
          });
        } catch (error) {
          console.log(error);
        }
      }

      try {
        serviceNamesResponse = await serviceNamesApi.getServiceNames({
          companyId: companyId,
        });
      } catch (error) {
        console.log(error);
      }

      try {
        servicePartnersResponse = await companiesApi.getCompanyPartnerships(
          companyId,
          { includeSelf: true }
        );
      } catch (error) {
        console.log(error);
      }

      try {
        companyUsersResponse = await usersApi.getUsers({
          companyId: companyId,
        });
      } catch (error) {
        console.log(error);
      }

      // try {
      //   applicationIntegrationTypesResponse =
      //     await applicationApi.getIntegrationTypes();

      //   const responseApplicationIntegrationTypes =
      //     applicationIntegrationTypesResponse?.data?.integrationTypes || [];
      //   responseApplicationIntegrationTypes.push({
      //     name: "+ Request Integration Support",
      //     id: "requestIntegration",
      //   });
      //   setApplicationIntegrationTypes(responseApplicationIntegrationTypes);
      //   // setSelectedFromResponse(
      //   //   applicationIntegrationTypesResponse,
      //   //   "integrationType",
      //   //   setIntegrationSettingsTypeName,
      //   //   "name"
      //   // );
      // } catch (error) {
      //   console.log(error);
      // }
      setAvailableChecklists(checklistResponse?.data || []);
      setAvailableServiceNames(serviceNamesResponse?.data || []);
      setAvailableServicers(servicePartnersResponse?.data || []);
      setAvailableSubAssigneeUsers(companyUsersResponse?.data || []);
    }

    // const setSelectedFromResponse = (
    //   response,
    //   propertySettingsKey,
    //   setState,
    //   labelKey
    // ) => {
    //   let matchingItem = {};
    //   if (propertySettingsKey === "integrationType") {
    //     console.log("response from match", response);
    //     console.log(propertySettings?.[propertySettingsKey]);
    //     matchingItem = response?.data?.integrationTypes?.find(
    //       (item) => item.id === propertySettings?.[propertySettingsKey]
    //     );
    //   } else {
    //     matchingItem = response?.data?.find(
    //       (item) => item.id === propertySettings?.[propertySettingsKey]
    //     );
    //   }

    //   if (matchingItem) {
    //     // console.log("matchingItem", matchingItem);
    //     setState(`${matchingItem?.[labelKey]} Integration`);
    //   } else {
    //     console.log("No matching item found");
    //     console.log("response", response);
    //   }
    // };

    fetchOtherElements();
  }, [propertySettings, companyId]);

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-success",
      cancelButton: "button button-error",
    },
    buttonsStyling: false,
    reverseButtons: true,
  });

  const showAlert = (title, message, action) => {
    Swal.fire(title, message, action);
  };

  const [syncLoading, setSyncLoading] = useState(false);
  const handleSyncNowIntegration = async () => {
    setSyncLoading(true);
    console.log("Sync Now Integration Clicked");
    try {
      // Add companyId property to updatedPropertySettings
      const response = await guestBookApi.upsertGuestBook(propertyId);

      if (response.status >= 200 && response.status < 300) {
        if (response.data?.status === "Processing") {
          showAlert(
            "Success",
            t(
              "properties.components.integrationSettingsDataCard.propertyIntegrationSettings.guestBookSyncing"
            ),
            "success"
          );
          if (triggerExternalCalendarFetch) {
            // Wait 5 seconds, then refresh calendar
            setTimeout(() => {
              triggerExternalCalendarFetch();
            }, 5000);
          } else {
            console.log("No triggerExternalCalendarFetch function provided");
          }
        } else if (response.data.status === "Calendar sync disabled") {
          showAlert(
            "Hmm...",
            "Calendar sync is disabled. Please enable in Property integration settings.",
            "warning"
          );
        }
      }
    } catch (error) {
      console.log(error);
      showAlert("Uh oh", "Guestbook sync failed", "error");
    }
    setSyncLoading(false);
  };
  const handlePropertySettingsSubmitClicked = () => {
    console.log("Property Settings Submit Clicked");
  };

  const handleIntegrationSettingsSubmitClicked = () => {
    console.log("Integration Settings Submit Clicked");
  };

  return (
    <Card
      sx={{
        height: "100%",
        justifyContent: { xs: "center" },
        width: "100%",
        "@media screen and (min-width: 768px)": { width: "100%" },
      }}
    >
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <ArgonTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Calendar Integration Settings
          {/* {integrationSettingsTypeName} */}
        </ArgonTypography>

        <ArgonTypography variant="body2" color="secondary">
          <Tooltip
            title={t(
              "properties.components.integrationSettingsDataCard.propertyIntegrationSettings.editPropertyIntegrationSettings"
            )}
            placement="top"
            onClick={() => {
              setIntegrationSettingsOpen(true);
            }}
          >
            <Icon>edit</Icon>
          </Tooltip>
        </ArgonTypography>
        <HowToVideoButton videoId={video} />

        <HostHiveDialog
          includeDelete={false}
          includeSave={false}
          includeClose={false}
          open={integrationSettingsOpen}
          helpIntegrationLookupKey="propertyIntegrationSettings"
          onClose={() => {
            setIntegrationSettingsOpen(false);
          }}
          onSave={handleIntegrationSettingsSubmitClicked}
          title={t(
            "properties.components.integrationSettingsDataCard.propertyIntegrationSettings.title"
          )}
          fullScreen={false}
          dialogObjects={
            <IntegrationSettingsDataCard
              title="Setting"
              setIntegrationSettingsTypeName={setIntegrationSettingsTypeName}
              propertySettings={propertySettings}
              setPropertySettings={setPropertySettings}
              propertyId={propertyId}
              companyId={companyId}
              handleClose={() => {
                setIntegrationSettingsOpen(false);
              }}
              availableChecklists={availableChecklists}
              availableServiceNames={availableServiceNames}
              availableServicers={availableServicers}
              availableSubAssigneeUsers={availableSubAssigneeUsers}
              applicationIntegrationTypes={applicationIntegrationTypes}
              setAvailableChecklists={setAvailableChecklists}
              setAvailableServiceNames={setAvailableServiceNames}
              setAvailableServicers={setAvailableServicers}
              setAvailableSubAssigneeUsers={setAvailableSubAssigneeUsers}
              setApplicationIntegrationTypes={setApplicationIntegrationTypes}
              triggerExternalCalendarFetch={triggerExternalCalendarFetch}
              hasPlusMembership={hasPlusMembership}
            />
          }
        />
      </ArgonBox>
      <ArgonBox
        p={2}
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        {/* Property Information */}
        <ArgonBox>
          <ArgonBox display="flex" py={1} pr={2}>
            <ArgonTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {t(
                "properties.components.integrationSettingsDataCard.syncStatus"
              )}
              : &nbsp;
            </ArgonTypography>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              &nbsp;Success
            </ArgonTypography>
          </ArgonBox>
          {/* <ArgonBox display="flex" py={1} pr={2}>
            <ArgonTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {t("properties.components.integrationSettingsDataCard.lastSync")}:
              &nbsp;
            </ArgonTypography>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              &nbsp;9:00PM
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox display="flex" py={1} pr={2}>
            <ArgonTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {t("properties.components.integrationSettingsDataCard.nextSync")}:
              &nbsp;
            </ArgonTypography>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              &nbsp;9:30PM
            </ArgonTypography>
          </ArgonBox> */}
        </ArgonBox>

        {/* Edit Property/View Notes & Alerts */}
        <ArgonBox justifyContent="flex-end" display="flex" py={1} pr={2}>
          <ArgonBox>
            {/* <ViewLinkModal
              title="View Integration Settings"
              action={handleIntegrationSettingsClicked}
            /> */}

            <ArgonBox display="flex" py={1} pr={2}>
              {!syncLoading ? (
                <ArgonButton
                  variant="gradient"
                  color="info"
                  onClick={handleSyncNowIntegration}
                  // disabled={
                  //   !propertySettings?.integrationUrl ||
                  //   propertySettings?.calendarSyncEnabled !== true
                  // }
                >
                  {t("buttons.syncNow")}
                </ArgonButton>
              ) : (
                <HostHiveLoading />
              )}
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

// Typechecking props for the PropertyInfoCard
PropertyInfoCard.propTypes = {
  title: PropTypes.string,
};

export default PropertyInfoCard;
