import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useMemo } from "react";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  CircularProgress,
  LinearProgress,
  // Typography,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { position } from "@chakra-ui/react";
import colors from "assets/theme/base/colors";
import { dark } from "@mui/material/styles/createPalette";
import { Icon } from "@mui/material";
import ThinBarChart from "layouts/wrappedReport/components/VisitorInsights/components/BarCharts/ThinBarChart";
import DefaultLineChart from "./components/LineCharts/DefaultLineChart";
import { Bar } from "react-chartjs-2";
// import ThinBarChart from "./components/LineCharts/ThinBarChart";
import CardTitle from "../CardTitle";
import { size } from "lodash";
function VisitorInsights({ data }) {
  const descriptions = [
    {
      color: "info",
      size: "sm",
      badgeContent: "Check-ins",
    },
    {
      color: "dark",
      size: "sm",
      badgeContent: "Check-outs",
    },
    {
      color: "primary",
      size: "sm",
      badgeContent: "Average Occupancy",
    },
  ];
  return (
    <Card>
      <ArgonBox
        sx={{
          padding: 2,
          minHeight: "500px",
        }}
      >
        <CardTitle title="Season Occupancy" />
        <CardContent>
          <ArgonBox display="flex" ml={-1}>
            {descriptions.map((description) => (
              <ArgonBadgeDot
                color={description.color}
                size={description.size}
                badgeContent={description.badgeContent}
              />
            ))}
          </ArgonBox>
          <DefaultLineChart chart={data} />
        </CardContent>
      </ArgonBox>
    </Card>
  );
}
export default VisitorInsights;
