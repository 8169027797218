/* eslint-disable react/prop-types */

import HostHiveDialog from "components/HostHiveDialog";
import MultiSelectFilter from "components/HostHiveMultiSelectFilter";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";

function FilterByCategory({
  triggerFetch,
  setTriggerFetch,
  selectedCategories,
  setSelectedCategories,
  categoryFilterSelectionVisible,
  setCategoryFilterSelectionVisible,
  availableCategories,
  setAvailableCategories,
}) {
  const handleFilterByCategory = () => {
    if (triggerFetch) {
      setTriggerFetch(!triggerFetch);
    }
    setCategoryFilterSelectionVisible(false);
  };

  return (
    <>
      <HostHiveDialog
        open={categoryFilterSelectionVisible}
        onClose={() => setCategoryFilterSelectionVisible(false)}
        onSave={() => handleFilterByCategory()}
        includeSave={true}
        // includeDelete={true}
        deleteText="Clear"
        includeClose={true}
        title="Filter by Category"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Apply"
        dialogObjects={
          <>
            <MultiSelectFilter
              selectType="categories"
              selectedItems={selectedCategories}
              setSelectedItems={setSelectedCategories}
              categoryType={"accounting"}
              availableItems={availableCategories}
              setAvailableItems={setAvailableCategories}
            />
          </>
        }
      />
    </>
  );
}

export default FilterByCategory;
