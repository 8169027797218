/* eslint-disable react/prop-types */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

import { useFormik } from "formik";
// import CoalescerErrorMessage from "components/ArgonErrorMessage";
// import { testCustomerData } from "layouts/customers/data/testCustomerData";
// Material Dashboard 2 React components
// import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
// import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
// import ArgonSelect from "components/ArgonSelect";
// import ArgonAlert from "components/ArgonAlert";
// import ArgonDialog from "components/ArgonDialog";
// import ProjectsApi from "api/projectsApi";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState, useMemo } from "react";
import ArgonBox from "components/ArgonBox";
// import { convertListToOptions } from "services/helper";
// // Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

// import CompaniesApi from "api/companiesApi";
// import RepositoriesApi from "api/repositoriesApi";
// import {
//   today,
//   year,
//   month,
//   day,
//   daysOfWeek,
//   dayOfWeek,
//   monthName,
//   formattedDate,
//   start_date,
//   convertTimeStamp,
// } from "services/dateUtils";
// import SectionHeader from "components/ArgonSectionHeader";
// import { stringify } from "ajv";
// import CustomerSchema from "schemas/customerSchema";
// import { Divider } from "@mui/material";
// import { set } from "lodash";
import PropertiesApi from "api/propertiesApi";
import CategoriesApi from "api/categoriesApi";
function MultiSelectFilter({
  selectType,
  selectedItems,
  setSelectedItems,
  availableItems,
  setAvailableItems,
  categoryType = null,
}) {
  const propertiesApi = new PropertiesApi();
  const categoriesApi = new CategoriesApi();

  const [] = useState([]);
  const [search, setSearch] = useState("");

  const getProperties = async () => {
    try {
      const results = await propertiesApi.getProperties();
      setAvailableItems(results.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCategories = async () => {
    try {
      if (categoryType) {
        const queryParams = {
          type: categoryType,
        };
        const results = await categoriesApi.getCategories(queryParams);
        setAvailableItems(results.data);
      } else {
        console.log("Category Type is required");
        throw new Error("Category Type is required");
      }
      // const results = await categoriesApi.getCategories();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectType === "categories") {
      getCategories();
    } else if (selectType === "properties") {
      getProperties();
    }
  }, [selectType]);

  const filteredItems = useMemo(() => {
    return search.length === 0
      ? availableItems
      : availableItems.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        );
  }, [search, availableItems]);

  const allSelected = useMemo(() => {
    return (
      selectedItems.length === availableItems.length && selectedItems.length > 0
    );
  }, [selectedItems, availableItems]);

  const handleItemsSelected = (item, event) => {
    setSelectedItems(
      event.target.checked
        ? [...selectedItems, item.id]
        : selectedItems.filter((selectedItem) => selectedItem !== item.id)
    );
  };

  const toggleSelectAll = () => {
    setSelectedItems(allSelected ? [] : availableItems.map((item) => item.id));
  };

  return (
    <ArgonBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ArgonBox pr={4} mb={4}>
            <ArgonInput
              label="Search"
              variant="outlined"
              size="medium"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={12}>
          <ArgonBox display="flex" alignItems="center" mb={4}>
            <ArgonButton
              variant="outlined"
              size="small"
              color="info"
              onClick={toggleSelectAll}
            >
              {allSelected ? "Deselect All" : "Select All"}
            </ArgonButton>
          </ArgonBox>
        </Grid>
        <ArgonBox display="column" alignItems="center" sx={{ maxHeight: 400 }}>
          {filteredItems.map((item) => (
            <Grid item xs={12} key={item.id}>
              <ArgonBox display="flex" alignItems="center" pl={1}>
                <Checkbox
                  checked={selectedItems.includes(item.id)}
                  onChange={(event) => handleItemsSelected(item, event)}
                />
                <ArgonBox ml={1}>
                  <ArgonTypography variant="caption" fontWeight="medium">
                    {item.name}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </Grid>
          ))}
        </ArgonBox>
      </Grid>
    </ArgonBox>
  );
}
export default MultiSelectFilter;
