/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import Icon from "@mui/material/Icon";
import ArgonTypography from "components/ArgonTypography";

// react-images-viewer components
import ImgsViewer from "react-images-viewer";

// @mui material components
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import { useTranslation } from "react-i18next";
// Images
const image1 =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-thumb.jpg";
const image2 =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-thumb-1.jpg";
const image3 =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-thumb-2.jpg";
const image4 =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-thumb-4.jpg";
const image5 =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-thumb-5.jpg";

const defaultImages = [
  { src: image1 },
  { src: image2 },
  { src: image3 },
  { src: image4 },
  { src: image5 },
];

function HostHiveImages({
  images = [],
  handleDeleteImage = null,
  showNoImagesMessage = false,
  imageGridSizeXs = 2.4,
  imageGridSizeMd = 2.4,
  buttonTextRemove = null,
  includeFileName = false,
}) {
  const { t } = useTranslation("common");
  const [currentImage, setCurrentImage] = useState(images[0]?.src);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
    openImgsViewer();
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [deleteMode, setDeleteMode] = useState(false);

  const deleteImage = async (index, key, fileName) => {
    // if (!handleDeleteImage) return;
    handleDeleteImage(index, fileName);
    //console.log("Delete image", key);
  };
  // If images.length > 0, then showDeleteButton = true

  const downloadImage = (image) => {
    fetch(image.src, { mode: "cors" })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${image.fileName}.jpg`; // Use the provided fileName with appropriate extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Image download failed:", error));
  };
  return (
    <ArgonBox>
      <ImgsViewer
        imgs={images.map((image) => ({
          src: image.src,
          // caption: image.fileName
          //   ? image.fileName
          //   : "Caption to support the image",
          // alt: image.fileName ? image.fileName : "I don't know",
        }))}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
        customControls={[
          <button
            key="download"
            onClick={() => downloadImage(images[imgsViewerCurrent])}
          >
            <Icon>download</Icon>
          </button>,
        ]}
      />

      <ArgonBox mt={2} pt={1}>
        <Grid container spacing={3}>
          {images?.map(({ src, key, fileName }, index) => {
            return (
              <Grid
                item
                xs={imageGridSizeXs}
                md={imageGridSizeMd}
                key={index}
                style={{ position: "relative" }}
              >
                <ArgonBox>
                  <ArgonBox
                    component="img"
                    id={index}
                    src={src}
                    alt={`small image ${index}`}
                    borderRadius="lg"
                    shadow="md"
                    width="100%"
                    sx={{
                      cursor: "pointer",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    onClick={() => {
                      handleSetCurrentImage({
                        currentTarget: { src, id: index },
                      }) && openImgsViewer();
                    }}
                    onMouseEnter={() => setHoveredImageIndex(index)} // Update here
                    onMouseLeave={() => setHoveredImageIndex(null)} // Update here
                  />
                  {/* File name */}
                  {includeFileName && (
                    <ArgonBox
                      display="flex"
                      justifyContent="center"
                      mt={0.5}
                      mb={2}
                    >
                      <ArgonTypography variant="caption">
                        {fileName}
                      </ArgonTypography>
                    </ArgonBox>
                  )}

                  {deleteMode ? (
                    <ArgonBox
                      display="flex"
                      justifyContent="center"
                      mt={0.5}
                      mb={2}
                    >
                      <ArgonButton
                        variant="outlined"
                        color="error"
                        size="small"
                        iconOnly
                        circular
                        onClick={() => deleteImage(index, key, fileName)}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                      </ArgonButton>
                    </ArgonBox>
                  ) : null}
                </ArgonBox>
              </Grid>
            );
          })}
        </Grid>
        {handleDeleteImage ? (
          <>
            {images.length > 0 ? (
              <>
                <ArgonBox display="flex" justifyContent="center" mt={2} mb={2}>
                  {deleteMode ? (
                    buttonTextRemove ? (
                      <ArgonTypography
                        variant="caption"
                        textTransform="capitalize"
                        sx={{
                          ":hover": { textDecoration: "underline" },
                          cursor: "pointer",
                        }}
                        onClick={() => setDeleteMode(false)}
                      >
                        Finish Removing Images
                      </ArgonTypography>
                    ) : (
                      <ArgonButton
                        variant="outlined"
                        color="success"
                        onClick={() => setDeleteMode(false)}
                      >
                        {t("general.photos.finishRemovingImages")}
                      </ArgonButton>
                    )
                  ) : buttonTextRemove ? (
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": { textDecoration: "underline" },
                        cursor: "pointer",
                      }}
                      onClick={() => setDeleteMode(true)}
                    >
                      Remove Images
                    </ArgonTypography>
                  ) : (
                    <ArgonButton
                      variant="outlined"
                      color="error"
                      onClick={() => setDeleteMode(true)}
                    >
                      {t("general.photos.removeImages")}
                    </ArgonButton>
                  )}
                </ArgonBox>
              </>
            ) : (
              <>
                {!showNoImagesMessage ? (
                  <>
                    <ArgonBox
                      display="flex"
                      justifyContent="center"
                      mt={2}
                      mb={2}
                    >
                      <ArgonButton variant="outlined" color="primary">
                        {t("general.photos.noPhotos")}
                      </ArgonButton>
                    </ArgonBox>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : null}
      </ArgonBox>
    </ArgonBox>
  );
}

export default HostHiveImages;
