import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, Typography, Divider } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  CircularProgress,
  LinearProgress,
  // Typography,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { position } from "@chakra-ui/react";

import { color } from "framer-motion";
import { icon } from "leaflet";
function CardTitle({ title }) {
  return (
    <>
      <ArgonBox
        sx={{
          paddingLeft: 3,
        }}
      >
        <ArgonTypography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "black",
          }}
        >
          {title}
        </ArgonTypography>
      </ArgonBox>
      <Divider />
    </>
  );
}

export default CardTitle;
