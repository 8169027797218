import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  CircularProgress,
  LinearProgress,
  // Typography,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { position } from "@chakra-ui/react";

import { color } from "framer-motion";
import { icon } from "leaflet";
import CardTitle from "../CardTitle";
import QuickFactRow from "./components/QuickFactRow";
function QuickFacts({ data }) {
  return (
    <Card>
      <ArgonBox
        sx={{
          padding: 2,
          minHeight: "500px",
        }}
      >
        <CardTitle title="Quick Facts" />
        <CardContent>
          {data.map((quickFact, index) => (
            <QuickFactRow
              key={index}
              name={quickFact.name}
              value={quickFact.value}
              color={quickFact.color}
            />
          ))}
        </CardContent>
      </ArgonBox>
    </Card>
  );
}

export default QuickFacts;
