import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  CircularProgress,
  LinearProgress,
  // Typography,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { position } from "@chakra-ui/react";
import InfoCard from "./components/InfoCard";
import { color } from "framer-motion";
import { icon } from "leaflet";
import AISummary from "./components/AISummary";
import QuickFacts from "./components/QuickFacts";
import SeasonOccupancy from "./components/SeasonOccupancy";
import VisitorInsights from "./components/VisitorInsights";
import thinBarChartData from "layouts/dashboards/smart-home/data/thinBarChartData";
// import horizontalBarChartData from "layouts/ecommerce/overview/data/horizontalBarChartData";
// import defaultLineChartData from "layouts/applications/analytics/data/defaultLineChartData";
const horizontalBarChartData = {
  labels: ["Winter", "Spring", "Summer", "Fall"],
  datasets: [
    {
      label: "Sales by age",
      color: "dark",
      data: [15, 20, 12, 60],
    },
  ],
};

const defaultLineChartData = {
  labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [
    {
      label: "Check-ins",
      color: "info",
      data: [2, 3, 5, 7, 6, 8, 9, 10, 12],
    },
    {
      label: "Check-outs",
      color: "dark",
      data: [3, 4, 5, 6, 7, 8, 9, 10, 11],
    },
    {
      label: "Average Occupancy",
      color: "primary",
      data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
  ],
};

function WrappedReport() {
  const WorkCard = ({
    name,
    pools,
    totalPools,
    distance,
    timeStart,
    timeEnd,
  }) => {
    const progress = (pools / totalPools) * 100;
    return (
      <Card
        variant="outlined"
        sx={{
          padding: 2,
          display: "flex",
          alignItems: "center",
          minWidth: 300,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          position: "relative",
          marginBottom: 2,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* Left Section: Circular Progress */}
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                value={(pools / totalPools) * 100}
                size={45}
                thickness={4}
                sx={{ color: "#3f51b5" }} // Set to a similar blue as in the image
              />
              <Box
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textPrimary"
                >{`${pools}/${totalPools}`}</Typography>
              </Box>
            </Box>
          </Grid>

          {/* Right Section: Info */}
          <Grid item xs={8}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {distance} mi
            </Typography>

            {/* Progress bar for time */}
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 1, mb: 1, height: 2, backgroundColor: "#e0e0e0" }}
            />

            {/* Time display */}
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2" color="textSecondary">
                {timeStart}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {timeEnd}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Top-right more_vert button */}
        <IconButton
          aria-label="more"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Card>
    );
  };

  const RouteCard = ({ position, name, address, time }) => {
    return (
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          //   padding: 2,
          mb: 2,
          //   backgroundColor: position === 1 ? "#4CAF50" : "#f9f9f9", // Highlight first card
          //   color: position === 1 ? "#fff" : "#000",
          marginBottom: 2,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Grid container alignItems="center">
          {/* Position number */}
          <Grid
            item
            xs={2}
            sx={{
              backgroundColor: position === 1 ? "#4CAF50" : "#f0f0f0",
              color: position === 1 ? "#fff" : "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 80,
              width: 40,
              // borderRadius: "50%",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {position}
          </Grid>

          {/* Route details */}
          <Grid
            item
            xs={9}
            sx={{
              paddingLeft: 2,
            }}
          >
            <Grid container>
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: position === 1 ? "bold" : "normal",
                  }}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">{time}</Typography>
              </Grid>
            </Grid>

            <Typography variant="body2">{address}</Typography>
          </Grid>

          {/* Time */}
        </Grid>
        <IconButton
          aria-label="more"
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Card>
    );
  };

  const infoData = [
    {
      name: "Total Reservations",
      value: 10,
      percentageChange: 8,
      color: "lightpink",
      icon: "BarChartIcon",
      dataType: "count",
    },
    {
      name: "Total Occupancy Rate",
      value: 80,
      percentageChange: 5,
      color: "lightyellow",
      icon: "calendar_month",
      dataType: "percentage",
    },
    {
      name: "Average Duration",
      value: 15,
      percentageChange: 3,
      color: "lightgreen",
      icon: "BarChartIcon",
      dataType: "count",
    },
    {
      name: "Busiest Season",
      value: "Winter",
      percentageChange: 8,
      color: "lightpurple",
      icon: "BarChartIcon",
      dataType: "string",
    },
    {
      name: "Average Long stay duration",
      value: 9.6,
      percentageChange: 5,
      color: "lightblue",
      icon: "BarChartIcon",
      dataType: "count",
    },
    {
      name: "Top Check-in Day",
      value: "Friday",
      percentageChange: 3,
      color: "lightgreen",
      icon: "BarChartIcon",
      dataType: "string",
    },
    // {
    //   name: "Top Check-out Day",
    //   value: "Saturday",
    //   percentageChange: 3,
    //   color: "lightpurple",
    //   icon: "BarChartIcon",
    //   dataType: "string",
    // },
  ];

  const testQuickFacts = [
    {
      name: "Count of Weekend Checkouts",
      value: 6,
      color: "lightblue",
    },
    {
      name: "Longest Stay Duration",
      value: "12 Days",
      color: "lightgreen",
    },
    {
      name: "Top Check-out Day",
      value: "Saturday",
      color: "lightpurple",
    },
    {
      name: "Average Booking Lead Time",
      value: "16.86 Days",
      color: "lightorange",
    },
  ];

  return (
    <DashboardLayout>
      <ArgonBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                p={3}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <ArgonBox lineHeight={1}>
                      <ArgonTypography variant="h5" fontWeight="medium">
                        Year End Report
                      </ArgonTypography>
                      <ArgonTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Review your year end report
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>

        {/* Display work cards */}
        <ArgonBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InfoCard data={infoData} />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <QuickFacts data={testQuickFacts} />
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SeasonOccupancy data={horizontalBarChartData} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AISummary />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <VisitorInsights data={defaultLineChartData} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <QuickFacts data={testQuickFacts} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default WrappedReport;
