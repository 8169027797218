import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Card, CardContent, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  CircularProgress,
  LinearProgress,
  // Typography,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { position } from "@chakra-ui/react";
import colors from "assets/theme/base/colors";
import { dark } from "@mui/material/styles/createPalette";
import { Icon } from "@mui/material";
import InfoCardItem from "./components/InfoCardItem";
import CardTitle from "../CardTitle";
function InfoCard({ data }) {
  return (
    <Card>
      <ArgonBox
        sx={{
          padding: 2,
        }}
      >
        <CardTitle title="Guest Stays" />
        <ArgonBox
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2}>
              {data.map((item, index) => (
                <Grid item sm={12} lg={2} key={index}>
                  <InfoCardItem {...item} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}
export default InfoCard;
